<template>
    <div class="container-fluid add-form-list">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header d-flex justify-content-between">
                        <div class="header-title">
                            <h4 class="card-title">
                                商品(입고)
                            </h4>
                        </div>
                    </div>
                    <div class="card-body">
                        <tab-nav :tabs="true" id="myTab-1">
                            <tab-nav-items :active="true" id="import-tab" ariaControls="import" role="tab" :ariaSelected="true" title="입고" />
                            <tab-nav-items :active="false" id="location-tab" ariaControls="location" role="tab" :ariaSelected="false" title="위치" />
                            <tab-nav-items :active="false" id="list-tab" ariaControls="list" role="tab" :ariaSelected="false" title="입고리스트" />
                        </tab-nav>
                        <tab-content id="myTabContent">
                            <tab-content-item :active="true" id="import" aria-labelled-by="import-tab">
                                <div class="box-area">
                                    <b-form-input type="text" class="form-control mr-2" placeholder="바코드 입력" v-model="barcode" style="width: 30%"></b-form-input>
                                    <button class="btn btn-primary mr-2" type="button" @click="onChangeFileInput">사진찍기</button>
                                    <input type="file" ref="fileInput" accept="image/*" @change="onFileChange" capture="environment" style="display: none;" />
                                </div>
                                <div v-if="imageData">
                                    <div>
                                        <button class="btn btn-primary mr-2 mt-3" type="button" @click="onSubmit">등록</button>
                                        <button class="btn btn-primary mr-2 mt-3" type="button" @click="onChangeFileInput">재촬영</button>
                                    </div>
                                    <img :src="imageData" alt="Captured Image" />
                                </div>
                            </tab-content-item>
                            <tab-content-item :active="false" id="location" aria-labelled-by="location-tab">
                                <div style="display: flex">
                                    <b-form-input type="text" class="form-control mr-2" placeholder="바코드 입력" v-model="validateBarcode" style="width: 35%" @keyup.enter="focusNextInput" ref="validateBarcodeInput"></b-form-input>
                                    <b-form-input type="text" class="form-control mr-2" placeholder="위치 바코드 입력" v-model="locationBarcode" style="width: 40%" ref="locationBarcodeInput" @keyup.enter="onLocationSubmit"></b-form-input>
                                    <button class="btn btn-primary" type="button" @click="onLocationSubmit">등록</button>
                                </div>
                            </tab-content-item>
                            <tab-content-item :active="false" id="list" aria-labelled-by="list-tab">
                                <CDataTable v-if="showTable" :headers="headers" :items="logisImportData" :is-column="false" :is-radio="false" :is-per-page="false">
                                    <template v-slot:imageUrl="{item}">
                                        <div style="margin: 10px 0">
                                            <a href="javascript:void(0)" v-b-modal="imageModalID(item._id)">
                                                <img :src="`${item.imageUrl}?timestamp=${Date.now()}`" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                            </a>
                                            <b-modal :id="'imageModal' + item._id" title="이미지 상세 보기" size="lg" scrollable class="detail-modal" hide-footer>
                                                <img :src="item.imageUrl" alt="Full screen image" style="width: 100%; height: 100%">
                                            </b-modal>
                                        </div>
                                    </template>
                                    <template v-slot:date="{item}">
                                        <div>
                                            {{ getTimeDate(item.date) }}
                                        </div>
                                    </template>
                                    <template v-slot:action="{item}">
                                        <div class="d-flex align-items-center list-action justify-center">
                                            <a class="badge badge-info mr-2 action-button" data-toggle="tooltip" v-b-tooltip.top title="수정" href="javascript:void(0)" v-b-modal="modalID(item._id)">
                                                <i class="ri-eye-line mr-0 action-img"></i>
                                            </a>
                                            <b-modal :id="'modal' + item._id" size="sm" scrollable title="수정" ok-title="수정" cancel-title="닫기" class="detail-modal" @hidden="onModalHidden" @ok="updateImage(item._id)">
                                                <div class="box-area">
                                                    <b-form-input type="text" class="form-control mr-2" placeholder="바코드 입력" v-model="updateBarcode" style="width: 30%"></b-form-input>
                                                    <button class="btn btn-primary mr-2" type="button" @click="onChangeFileInput">사진찍기</button>
                                                    <input type="file" ref="fileInput" accept="image/*" @change="onUpdateFileChange" capture="environment" style="display: none;" />
                                                </div>
                                                <div v-if="updateImageData">
                                                    <img :src="updateImageData" alt="Captured Image" />
                                                </div>
                                            </b-modal>
                                            <a class="badge bg-warning mr-2 action-button" data-toggle="tooltip" v-b-tooltip.top title="삭제" href="javascript:void(0)" @click="deleteObject(item._id)">
                                                <i class="ri-delete-bin-line mr-0 action-img"></i>
                                            </a>
                                        </div>
                                    </template>
                                </CDataTable>
                            </tab-content-item>
                        </tab-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import CDataTable from '@/components/common/CDataTable';

export default {
    name:'logisimportbox',
    components: {
        CDataTable,
    },
    data() {
        return {
            imageData: null,
            barcode: '',
            validateBarcode: '',
            locationBarcode: '',
            headers: [
                {text: '이미지', value: 'imageUrl', align: 'center', width: 100, isSlot: true},
                {text: '바코드', value: 'barcode', align: 'center', width: 100, isSlot: false},
                {text: '위치 바코드', value: 'locationBarcode', align: 'center', width: 100, isSlot: false},
                {text: '등록일', value: 'date', align: 'center', width: 100, isSlot: true},
                {text: 'Action', value: 'action', align: 'center', width: 150, isSlot: true}
            ],
            logisImportData: null,
            showTable: false,
            showModal: false,
            selectedImage: '',
            updateBarcode: '',
            updateImageData: null
        };
    },
    created() {
        this.getImageData()
    },
    methods: {
        getImageData() {
            this.showTable = false
            axios.post('/api/logisImportBox/images')
                .then((response) => {
                    this.logisImportData = response.data
                    this.showTable = true
                })
        },
        onChangeFileInput() {
            this.imageData = ''
            this.$refs.fileInput.click();
        },
        onFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imageData = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        onUpdateFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.updateImageData = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        onSubmit() {
            const date = Date.now();
            if (this.barcode && this.imageData) {
                const payload = {
                    barcode: this.barcode,
                    imageData: this.imageData,
                    date: date
                };

                axios.post('/api/logisImportBox/image', payload)
                    .then(response => {
                        if (response.data.요청결과 === 'success') {
                            this.getImageData()
                            this.imageData = null
                            this.barcode = ''
                        }
                    })
                    .catch(e => {
                        alert(e.response.data.message)
                    })
            } else {
                alert('바코드와 사진은 필수입니다.')
            }
        },
        getDate(timestamp){
            let date = new Date(timestamp);
            let year = date.getFullYear();
            let month = ("0" + (1 + date.getMonth())).slice(-2);
            let day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        },
        getTimeDate(timestamp) {
            let date = new Date(timestamp);
            let year = date.getFullYear();
            let month = ("0" + (1 + date.getMonth())).slice(-2);
            let day = ("0" + date.getDate()).slice(-2);
            let hours = ("0" + date.getHours()).slice(-2);
            let minutes = ("0" + date.getMinutes()).slice(-2);
            let seconds = ("0" + date.getSeconds()).slice(-2);

            return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
        },
        onModalHidden() {
            this.updateImageData = null
            this.updateBarcode = ''
        },
        modalID(index) {
            return 'modal' + index
        },
        imageModalID(index) {
            return 'imageModal' + index
        },
        updateImage(id) {
            const payload = {
                barcode: this.updateBarcode,
                imageData: this.updateImageData,
                id: id
            };

            axios.post(`/api/logisImportBox/update/${id}`, payload)
                .then(response => {
                    if (response.data.요청결과 === 'success') {
                        this.onModalHidden()
                        this.updateImageData = null
                        this.updateBarcode = ''
                        this.getImageData()
                    }
                })
                .catch(e => {
                    console.log(e)
                    alert(e.response.data.message)
                })
        },
        deleteObject(id) {
            if (confirm('삭제된 내역은 복구가 불가능합니다.')) {
                axios.post(`/api/logisImportBox/delete/${id}`)
                    .then(response => {
                        if (response.data.요청결과 === 'success') {
                            this.updateImageData = null
                            this.updateBarcode = ''
                            this.getImageData()
                        }
                    })
                    .catch(e => {
                        alert(e.response.data.message)
                    })
            }
        },
        onLocationSubmit() {
            const payload = {
                validateBarcode: this.validateBarcode,
                locationBarcode: this.locationBarcode
            };

            if (this.validateBarcode && this.locationBarcode) {
                axios.post('/api/logisImportBox/location', payload)
                    .then((response) => {
                        if (response.data.요청결과 === 'success') {
                            this.validateBarcode = ''
                            this.locationBarcode = ''
                            this.getImageData()
                        }
                    })
                    .catch(e => {
                        alert(e.response.data.message)
                    })
            } else {
                alert('바코드와 위치바코드는 필수입니다.')
            }
        },
        focusNextInput() {
            this.$refs.locationBarcodeInput.focus();
        },
    },
};
</script>

<style scoped>
img {
    max-width: 100%;
    height: auto;
}
.box-area {
    display: flex;
    margin-bottom: 12px;
}

#quill-container{
    height: 50px;
}
#quill-container .ql-editor{
    min-height: unset;
}
.my-class .dropdown-menu {

    max-height: 300px;
    overflow-y: auto;
}
.modal-dialog {
    max-width: 80%;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.action-button {
    width: 30px;
    height: 30px;
}
.action-img {
    display: flex;
    justify-content: center;
    margin: 6px 0px;
}
</style>
